<template>
  <section class="data-list-container">

    <div class="mb-5">
      <new-button class="mr-5" @click="openAddNew">Adauga atribut nou</new-button>
    </div>

    <vx-card>
      <v-data-table
        :headers="headers"
        :hide-default-footer="true"
        :items="paginatedItems.data"
        @update:sort-by="updateSortField"
        @update:sort-desc="updateSortType"
      >

        <template v-slot:header="{props}">
          <tr>
            <th v-for="header in props.headers" :key="header.text">

              <template v-if="header.value === 'name'">
                <filter-input :filter-name="header.value" placeholder="Filter..." @filter="updateFilter"/>
              </template>

              <template v-if="header.value === 'base_product_category.name'">
                <category-filter filter-name="base_product_category" @filter="updateFilter"/>
              </template>

              <template v-if="header.value === 'actions'">
                <div class="text-center">
                  <help-icon position="left" text="Atributele se pot sterge doar daca nu sunt folosite niciude"/>
                </div>
              </template>

            </th>
          </tr>
        </template>

        <template v-slot:item.has_stock="{item}">
          <template v-if="item.has_stock">Da</template>
          <template v-else>Nu</template>
        </template>

        <template v-slot:item.actions="{item}">
          <edit-button @click="editItem(item)"></edit-button>
          <delete-button v-if="item.deletable" @delete="deleteItem(item)"/>
        </template>

        <template v-slot:footer>
          <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
          ></v-pagination>
        </template>

      </v-data-table>
    </vx-card>


  </section>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import EditButton from '@/components/general-form/EditButton'
import DeleteButton from '@/components/general-form/DeleteButton'
import FilterInput from '@/components/general-form/FilterInput'
import FilterByComponentType from '@/views/ProductManagement/Attributes/components/FilterByComponentType'
import HelpIcon from '@/components/HelpIcon'
import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'
import CategoryFilter from '@/views/Products/components/Filters/CategoryFilter'

export default {
  name: 'AttributeValuesList',
  components: {
    CategoryFilter,
    ProductCategorySelector,
    HelpIcon,
    FilterByComponentType,
    FilterInput,
    DeleteButton,
    EditButton,
    NewButton
  },
  data () {
    return {
      headers: [
        {
          text: 'Denumire',
          value: 'name',
          sortable: false
        },
        {
          text: 'Ordine Afisare',
          value: 'filter_display_order',
          sortable: false,
          width: '150',
          align:'center'
        },
        {
          text: '',
          value: 'actions',
          width: '150',
          sortable: false
        }
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    paginatedItems () {
      return this.$store.getters['attributeValues/paginatedData']
    },
    currentPage () {
      return this.$store.getters['attributeValues/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    openAddNew () {
      this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
        component: 'add-attribute-value',
        title: 'Adauga atribut nou'
      })
    },
    editItem (item) {
      this.$store.dispatch('attributeValues/editItem', item)
    },
    changePage (newPage) {
      this.$store.dispatch('attributeValues/changePage', newPage)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('attributeValues/deleteFilter', filterName)
      } else {
        this.$store.dispatch('attributeValues/addFilter', {
          filterName,
          searchValue
        })
      }
    },
    loadItems () {
      this.$store.dispatch('attributeValues/loadItems')
    },
    deleteItem (item) {
      this.$store.dispatch('attributeValues/deleteItem', item.id)
    }
  },
  watch: {
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('attributeValues/changeSort', newValue)
      }
    }
  },
  created () {
    this.$store.dispatch('attributeValues/setAttributeId', this.$route.params.id)
    this.loadItems()
  }
}
</script>
